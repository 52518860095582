import { CSSProperties, ClipboardEvent, ComponentType } from 'react';
import styled from 'styled-components';
import {
  Checkbox as AqCheckbox,
  Field,
  GroupFieldLayout,
  NumberInput,
  RadioGroup as AqRadioGroup,
  RadioGroupItem,
  RadioGroupValue,
  TextInput,
} from '@ardoq/forms';
import { DateOnlyPicker, DateTimePicker } from '@ardoq/date-time-picker';
import { isDateStringValid, parseLocaleDateString } from '@ardoq/date-time';
import { RichTextReader } from '@ardoq/rich-text-editor';
import { getCurrentLocale } from '@ardoq/locale';
import {
  CreatableMultiselect,
  Multiselect,
  SortableMultiselect,
} from '@ardoq/select';
import List from 'scopeData/editors/List';
import User from 'scopeData/editors/user/User';
import FileSelector from 'scopeData/editors/file/FileSelector';
import {
  ColorPickerProps,
  CreatableMultiListEditorProps,
  DateRangeEditorProps,
  DateRangeValidationError,
  ListEditorProps,
  MetaEditorProps,
  MultiListEditorProps,
  RemovableMetaEditorProps,
  SingleValueEditorProps,
  TextAreaEditorProps,
  RadioGroupEditorProps,
} from 'scopeData/editors/types';
import { FieldType } from '@ardoq/renderers';
import { ColorPickerInput } from '@ardoq/color-picker';
import { SecondaryButton } from '@ardoq/button';
import {
  ENTER_TAG_NAME_MESSAGE,
  INVALID_TAG_NAME_MESSAGE,
  REMOVE_FIELD_MESSAGE,
} from 'models/consts';
import { formatDateOnly } from '@ardoq/date-time';
import { tagNameValidator } from 'scopeData/editors/validators';
import ExternallyManagedIndicatorWithPopover from 'externallyManaged/ExternallyManagedIndicatorWithPopover';
import DebouncedInput from './DebouncedInput';
import IconSelector from './IconSelector';
import ImageSelector from './ImageSelector';
import ShapeSelector from './ShapeSelector';
import { Space } from '@ardoq/style-helpers';
import { getPastedOptions } from './getPastedOptions';
import { APIEntityType } from '@ardoq/api-types';
import {
  GenerateDescriptionButton,
  GenerateDescriptionButtonProps,
  useGenerateDescriptionState,
} from '@ardoq/snowflakes';
import { api, componentApi } from '@ardoq/api';
import { Features, hasFeature } from '@ardoq/features';
import { trackEvent } from '../../tracking/tracking';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import {
  TransformationsEditor,
  TransformationsEditorProps,
} from './calculatedFields/TransformationsEditor';
import { SubdivisionMembershipsView } from 'subdivisions/subdivisionCreationContext/SubdivisionMembershipsView';
import { SubdivisionCreationContextEditor } from 'subdivisions/subdivisionCreationContext/SubdivisionCreationContextEditor';
import {
  defaultExtensions,
  RichTextEditorTransition,
} from '@ardoq/rich-text-editor-2024';

const RichTextEditorWrapper = styled(Field)`
  .tui-editor {
    word-break: break-word;
  }
  .tui-toolbar-icons {
    &.tui-image-custom-icon {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="%2337475A"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/></svg>');
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-size: auto;
    }
  }
`;

const Text = ({
  label,
  value,
  isDirty,
  errorMessages,
  description,
  isReadOnly,
  isDisabled,
  disabledMessage,
  dataClickId,
  onKeyDown,
  onValueChange,
  onBlur,
  popoverHelpContent,
  onCloseFieldClick,
  closeFieldIconTooltip,
  defaultValue,
}: SingleValueEditorProps) => (
  <TextInput
    defaultValue={defaultValue as string | number} // we know that Text component is used only for text of number fields
    label={label}
    value={(value as string) || undefined}
    errorMessage={isDirty ? (errorMessages?.[0] as string) : undefined}
    helperText={isDisabled ? disabledMessage : description}
    isReadOnly={isReadOnly}
    isDisabled={isDisabled}
    onValueChange={onValueChange}
    onBlur={onBlur}
    popoverHelpContent={popoverHelpContent}
    data-click-id={dataClickId}
    onKeyDown={onKeyDown}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  />
);

const DebouncedText = DebouncedInput(
  Text as ComponentType<Omit<SingleValueEditorProps, 'value'>> // DebouncedInput does not pass down value prop, but relies on defaultValue prop instead
);

const DefaultValueInfo = () => {
  return (
    <>
      <p>
        Default value applies only to components and references added after the
        field type is created.
      </p>
      <p>
        To set preexisting components or references to the default value, right
        click the cell in the grid editor and set it in the context menu.
      </p>
    </>
  );
};

const TextArea = ({
  label,
  value,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  onCloseFieldClick,
  closeFieldIconTooltip,
  toolbarExtensions,
  config,
}: TextAreaEditorProps) => (
  <RichTextEditorWrapper
    label={label}
    helperText={isDisabled ? disabledMessage : description}
    isDisabled={isDisabled}
    popoverHelpContent={popoverHelpContent}
    data-click-id={dataClickId}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  >
    {isDisabled ? (
      <RichTextReader content={value as string} />
    ) : (
      <RichTextEditorTransition
        content={value as string}
        onInputChange={onValueChange}
        toolbarExtensions={toolbarExtensions}
        config={config}
      />
    )}
  </RichTextEditorWrapper>
);

const Number = ({
  label,
  value,
  errorMessages,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: SingleValueEditorProps) => (
  <NumberInput
    label={label}
    value={(value as number) ?? undefined}
    errorMessage={errorMessages?.[0] as string}
    helperText={isDisabled ? disabledMessage : description}
    isDisabled={isDisabled}
    onValueChange={onValueChange}
    allowDecimals={true}
    showUpDownArrows={!isDisabled}
    popoverHelpContent={popoverHelpContent}
    data-click-id={dataClickId}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  />
);

const Checkbox = ({
  label,
  value,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: SingleValueEditorProps) => (
  <AqCheckbox
    label={label}
    isChecked={!!value}
    isDisabled={isDisabled}
    onChange={() => onValueChange(!value)}
    helperText={isDisabled ? disabledMessage : description}
    data-click-id={dataClickId}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  >
    {label}
  </AqCheckbox>
);

const MultipleList = ({
  label,
  value,
  description,
  dataClickId,
  options,
  onValueChange,
  isDisabled,
  disabledMessage,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: MultiListEditorProps) => {
  return (
    <Multiselect
      label={label}
      value={value}
      options={options}
      onValueChange={onValueChange}
      isDisabled={isDisabled}
      helperText={isDisabled ? disabledMessage : description}
      placeholder="Select options..."
      closeMenuOnSelect={false}
      backspaceRemovesValue
      clickId={dataClickId}
      onCloseFieldClick={onCloseFieldClick}
      closeFieldIconTooltip={closeFieldIconTooltip}
    />
  );
};

const CreatableMultipleList = ({
  label,
  value,
  creationValidator,
  invalidOptionMessage,
  description,
  dataClickId,
  options,
  onValueChange,
  isDisabled,
  disabledMessage,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: CreatableMultiListEditorProps) => {
  return (
    <Field
      onPaste={(event: ClipboardEvent) => {
        event.preventDefault();
        const existingOptions = value ?? [];
        const pastedText = event.clipboardData.getData('text/plain');
        if (!pastedText) {
          return;
        }
        const pastedOptions = getPastedOptions(pastedText, creationValidator);
        if (!pastedOptions.length) {
          return;
        }
        onValueChange([...existingOptions, ...pastedOptions]);
      }}
    >
      <SortableMultiselect
        label={label}
        value={value}
        options={options}
        onValueChange={onValueChange}
        onChange={onValueChange}
        // @ts-expect-error issue in @ardoq/select
        isValidNewOption={creationValidator}
        noOptionsMessage={() => invalidOptionMessage}
        isDisabled={isDisabled}
        helperText={isDisabled ? disabledMessage : description}
        placeholder="Select options..."
        closeMenuOnSelect={false}
        backspaceRemovesValue
        clickId={dataClickId}
        onCloseFieldClick={onCloseFieldClick}
        closeFieldIconTooltip={closeFieldIconTooltip}
        isCreatable
      />
    </Field>
  );
};

const DateOnly = ({
  label,
  value,
  errorMessages,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  config,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: SingleValueEditorProps) => (
  <Field
    label={label}
    helperText={isDisabled ? disabledMessage : description}
    isDisabled={isDisabled}
    popoverHelpContent={popoverHelpContent}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  >
    <DateOnlyPicker
      date={parseLocaleDateString(value as string, getCurrentLocale())}
      config={config}
      isDisabled={isDisabled}
      onChange={newDate => {
        const isValid =
          !newDate || isDateStringValid(newDate, getCurrentLocale());
        if (isValid) {
          onValueChange(newDate);
        }
      }}
      errorMessage={errorMessages?.[0] as string}
      data-click-id={dataClickId}
    />
  </Field>
);

const DateTime = ({
  label,
  value,
  errorMessages,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  config,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: SingleValueEditorProps) => (
  <Field
    label={label}
    helperText={isDisabled ? disabledMessage : description}
    isDisabled={isDisabled}
    popoverHelpContent={popoverHelpContent}
    onCloseFieldClick={onCloseFieldClick}
    closeFieldIconTooltip={closeFieldIconTooltip}
  >
    <DateTimePicker
      date={parseLocaleDateString(value as string, getCurrentLocale())}
      config={config}
      isDisabled={isDisabled}
      onChange={newDate => {
        const newValue = newDate ? newDate.toISOString() : null;
        const isValid =
          !newValue || isDateStringValid(newValue, getCurrentLocale());
        if (isValid) {
          onValueChange(newValue);
        }
      }}
      errorMessage={errorMessages?.[0] as string}
      data-click-id={dataClickId}
    />
  </Field>
);

const DateOnlyRange = ({
  label,
  value,
  errorMessages,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  config,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: DateRangeEditorProps) => {
  const dateOnlyRange = value ? value : { start: null, end: null };
  const [errorMessage] = errorMessages ?? [];
  return (
    <Field
      label={label}
      helperText={isDisabled ? disabledMessage : description}
      isDisabled={isDisabled}
      popoverHelpContent={popoverHelpContent}
      errorMessage={typeof errorMessage === 'string' ? errorMessage : undefined}
      onCloseFieldClick={onCloseFieldClick}
      closeFieldIconTooltip={closeFieldIconTooltip}
    >
      <Space $align="flex-start">
        <DateOnlyPicker
          date={
            dateOnlyRange.start
              ? parseLocaleDateString(dateOnlyRange.start, getCurrentLocale())
              : null
          }
          config={config}
          isDisabled={isDisabled}
          onChange={newDate => {
            const isValid =
              !newDate || isDateStringValid(newDate, getCurrentLocale());
            if (isValid) {
              onValueChange({
                ...dateOnlyRange,
                start: newDate,
              });
            }
          }}
          errorMessage={
            (errorMessage as DateRangeValidationError)?.start as string
          }
          data-click-id={`${dataClickId}-start-date`}
        />
        <DateOnlyPicker
          date={
            dateOnlyRange.end
              ? parseLocaleDateString(dateOnlyRange.end, getCurrentLocale())
              : null
          }
          config={config}
          isDisabled={isDisabled}
          onChange={newDate => {
            const isValid =
              !newDate || isDateStringValid(newDate, getCurrentLocale());
            if (isValid) {
              onValueChange({
                ...dateOnlyRange,
                end: newDate,
              });
            }
          }}
          errorMessage={
            (errorMessage as DateRangeValidationError)?.end as string
          }
          data-click-id={`${dataClickId}-end-date`}
        />
      </Space>
    </Field>
  );
};

const DateTimeRange = ({
  label,
  value,
  errorMessages,
  description,
  isDisabled,
  disabledMessage,
  dataClickId,
  onValueChange,
  popoverHelpContent,
  config,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: DateRangeEditorProps) => {
  const dateTimeRange = value ? value : { start: null, end: null };
  const [errorMessage] = errorMessages ?? [];
  return (
    <Field
      label={label}
      helperText={isDisabled ? disabledMessage : description}
      isDisabled={isDisabled}
      popoverHelpContent={popoverHelpContent}
      errorMessage={typeof errorMessage === 'string' ? errorMessage : undefined}
      onCloseFieldClick={onCloseFieldClick}
      closeFieldIconTooltip={closeFieldIconTooltip}
    >
      <Space $align="flex-start">
        <DateTimePicker
          date={
            dateTimeRange.start
              ? parseLocaleDateString(dateTimeRange.start, getCurrentLocale())
              : null
          }
          config={config}
          isDisabled={isDisabled}
          onChange={newDate => {
            const start = newDate ? newDate.toISOString() : null;
            const isValid =
              !start || isDateStringValid(start, getCurrentLocale());
            if (isValid) {
              onValueChange({
                ...dateTimeRange,
                start,
              });
            }
          }}
          errorMessage={
            (errorMessage as DateRangeValidationError)?.start as string
          }
          data-click-id={`${dataClickId}-start-date`}
        />
        <DateTimePicker
          date={
            dateTimeRange.end
              ? parseLocaleDateString(dateTimeRange.end, getCurrentLocale())
              : null
          }
          config={config}
          isDisabled={isDisabled}
          onChange={newDate => {
            const end = newDate ? newDate.toISOString() : null;
            const isValid = !end || isDateStringValid(end, getCurrentLocale());
            if (isValid) {
              onValueChange({
                ...dateTimeRange,
                end,
              });
            }
          }}
          errorMessage={
            (errorMessage as DateRangeValidationError)?.end as string
          }
          data-click-id={`${dataClickId}-end-date`}
        />
      </Space>
    </Field>
  );
};

const RadioGroup = ({
  label,
  value,
  dataClickId,
  groupFieldLayout,
  options,
  onValueChange,
  popoverHelpContent,
  isReadOnly,
}: RadioGroupEditorProps) => {
  return (
    <AqRadioGroup
      label={label}
      value={value as RadioGroupValue}
      onValueChange={isReadOnly ? undefined : onValueChange}
      groupFieldLayout={groupFieldLayout}
      popoverHelpContent={popoverHelpContent}
    >
      {options.map(({ value: optionValue, label: optionLabel }, index) => (
        <RadioGroupItem
          key={index}
          isDisabled={isReadOnly && value !== optionValue}
          value={optionValue as RadioGroupValue}
          data-click-id={`${dataClickId}-${optionValue}`}
        >
          {optionLabel}
        </RadioGroupItem>
      ))}
    </AqRadioGroup>
  );
};

const ColorPickerWithoutReset = ({
  label,
  value,
  errorMessages,
  isDisabled,
  dataClickId,
  onValueChange,
}: ColorPickerProps) => {
  return (
    <Field label={label}>
      <ColorPickerInput
        value={value as string}
        isDisabled={isDisabled}
        onValueChange={onValueChange}
        errorMessage={errorMessages?.[0] as string}
        data-click-id={dataClickId}
      />
    </Field>
  );
};

const ColorPickerWithReset = ({
  label,
  value,
  defaultValue,
  errorMessages,
  isDisabled,
  dataClickId,
  onValueChange,
}: ColorPickerProps) => {
  return (
    <Field label={label}>
      <ColorPickerInput
        value={(value || defaultValue) as string}
        isDisabled={isDisabled}
        onValueChange={onValueChange}
        errorMessage={errorMessages?.[0] as string}
        data-click-id={dataClickId}
      />
      <SecondaryButton
        onClick={() => onValueChange(null)}
        dataTestId="reset-default-color"
        isDisabled={value === null || value === defaultValue}
      >
        Reset to default
      </SecondaryButton>
    </Field>
  );
};

const ColorPicker = (props: ColorPickerProps) => {
  const { hasResetButton, ...rest } = props;
  if (hasResetButton) {
    return <ColorPickerWithReset {...rest} />;
  }
  return <ColorPickerWithoutReset {...rest} />;
};

export const TagEditor = ({
  label,
  value,
  options,
  isDisabled,
  onValueChange,
}: CreatableMultiListEditorProps) => {
  return (
    <CreatableMultiselect
      isDisabled={isDisabled}
      label={label}
      value={value}
      options={options}
      onValueChange={onValueChange}
      placeholder="Select tags..."
      closeMenuOnSelect={false}
      backspaceRemovesValue
      isClearable={false}
      // @ts-expect-error issue in @ardoq/select
      isValidNewOption={tagNameValidator}
      formatCreateLabel={(label: string) => `Create tag '${label}'`}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? INVALID_TAG_NAME_MESSAGE : ENTER_TAG_NAME_MESSAGE
      }
      clickId="editor-input-tags"
    />
  );
};
type ComponentDescriptionEditorProps = TextAreaEditorProps &
  GenerateDescriptionButtonProps & { workspaceName: string };
const ComponentDescriptionEditor = ({
  value,
  onValueChange,
  config,
  componentName,
  componentTypeName,
  workspaceName,
  toolbarExtensions,
  label,
}: ComponentDescriptionEditorProps) => {
  const { state, commands } = useGenerateDescriptionState();

  const updatedToolbarExtensions = toolbarExtensions
    ? toolbarExtensions
    : [...defaultExtensions];
  updatedToolbarExtensions.push({
    name: 'Generate',
    generateFunction: async () =>
      componentApi.generateDescription({
        name: componentName,
        type: componentTypeName,
        description: value as string,
        workspaceName,
      }),
    trackEventFunction: trackEvent,
  });

  return (
    <RichTextEditorTransition
      content={value as string}
      label={label}
      onInputChange={onValueChange}
      minHeight="230px"
      errorMessage={state.errorMessage}
      warningMessage={state.reasonForNotBeingAbleToGenerateDescription}
      config={config}
      toolbarExtensions={updatedToolbarExtensions}
    >
      <GenerateDescriptionButton
        onClick={async () => {
          commands.onLoading();
          const response = await componentApi.generateDescription({
            name: componentName,
            type: componentTypeName,
            description: value as string,
            workspaceName,
          });
          commands.onDescriptionReceived();
          trackEvent(
            'Generate component description: clicked generate component description button in core app'
          );
          if (isArdoqError(response)) {
            commands.onError(getArdoqErrorMessage(response));
            api.logErrorIfNeeded(response);
          } else if (response.type === 'description') {
            onValueChange(response.text);
          } else {
            commands.onLLMCouldNotGenerateDescription(response.text);
            trackEvent(
              'Generate component description0 LLM could not generate description',
              {
                reason: response.text,
              }
            );
          }
        }}
        isLoading={state.isLoading}
        hasError={state.hasError}
        componentName={componentName}
        componentTypeName={componentTypeName}
        currentDescription={value as string}
      />
    </RichTextEditorTransition>
  );
};

const Editor = (props: MetaEditorProps) => {
  const { type, isReadOnly, ...rest } = props;
  const locale = getCurrentLocale();

  if (isReadOnly) {
    switch (type) {
      case FieldType.INHERITED_SUBDIVISIONS:
        return (
          <SubdivisionMembershipsView
            label={props.label}
            options={(rest as ListEditorProps).options}
          />
        );
      // when FieldType.POPULATE_METHOD is readOnly, don't render anything,
      // as it is shown as suffix for the field type
      case FieldType.POPULATE_METHOD:
        return null;
      default:
        return <Text {...(rest as SingleValueEditorProps)} isReadOnly />;
    }
  }

  switch (type) {
    case FieldType.NAME:
    case FieldType.TEXT:
    case FieldType.URL:
    case FieldType.EMAIL:
    case FieldType.DISPLAY_TEXT:
    case FieldType.LABEL:
    case FieldType.FIELD_DESCRIPTION:
      return <DebouncedText {...(rest as SingleValueEditorProps)} />;
    case FieldType.DEFAULT_VALUE_TEXT:
    case FieldType.DEFAULT_VALUE_URL:
    case FieldType.DEFAULT_VALUE_EMAIL:
      return (
        <DebouncedText
          {...(rest as SingleValueEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.TYPE_ID_COMPONENT:
    case FieldType.PARENT:
    case FieldType.TYPE_ID_REFERENCE:
    case FieldType.SOURCE_CARDINALITY:
    case FieldType.TARGET_CARDINALITY:
    case FieldType.SOURCE:
    case FieldType.TARGET:
    case FieldType.REFERENCE_TYPE_LINE:
    case FieldType.REFERENCE_TYPE_LINE_BEGINNING:
    case FieldType.REFERENCE_TYPE_LINE_ENDING:
    case FieldType.FIELD_TYPE:
    case FieldType.NUMBER_FORMAT_OPTIONS:
      return <List {...(rest as ListEditorProps)} />;
    case FieldType.SUBDIVISION_CREATION_CONTEXT:
      return (
        <SubdivisionCreationContextEditor {...(rest as ListEditorProps)} />
      );
    case FieldType.ICON:
      return <IconSelector {...(rest as SingleValueEditorProps)} />;
    case FieldType.IMAGE:
      return <ImageSelector {...(rest as ListEditorProps)} />;
    case FieldType.SHAPE:
      return <ShapeSelector {...(rest as SingleValueEditorProps)} />;
    case FieldType.LIST:
    case FieldType.START_VIEW:
    case FieldType.DEFAULT_PERSPECTIVE:
    case FieldType.DEFAULT_SORT:
      return <List {...(rest as ListEditorProps)} isClearable />;
    case FieldType.DESCRIPTION:
      return rest.entityType === APIEntityType.COMPONENT &&
        hasFeature(Features.AUTOCOMPLETE_OPENAI) ? (
        <ComponentDescriptionEditor
          {...(rest as ComponentDescriptionEditorProps)}
        />
      ) : (
        <TextArea {...(rest as TextAreaEditorProps)} />
      );
    case FieldType.TEXT_AREA:
      return <TextArea {...(rest as TextAreaEditorProps)} />;
    case FieldType.DEFAULT_VALUE_TEXT_AREA:
      return (
        <TextArea
          {...(rest as TextAreaEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.NUMBER:
      // case FieldType.ORDER: // reference order is deprecated
      return <Number {...(rest as SingleValueEditorProps)} />;
    case FieldType.DEFAULT_VALUE_NUMBER:
      return (
        <Number
          {...(rest as SingleValueEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.CHECKBOX:
    case FieldType.GLOBAL:
    case FieldType.GLOBALREF:
    case FieldType.RETURNS_VALUE:
    case FieldType.HAS_CARDINALITY:
      return <Checkbox {...(rest as SingleValueEditorProps)} />;
    case FieldType.SELECT_MULTIPLE_LIST:
    case FieldType.COMPONENT_TYPE:
    case FieldType.REFERENCE_TYPE:
      return <MultipleList {...(rest as MultiListEditorProps)} />;
    case FieldType.DATE_ONLY:
      return (
        <DateOnly
          {...(rest as SingleValueEditorProps)}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DATE_TIME:
      return (
        <DateTime
          {...(rest as SingleValueEditorProps)}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DEFAULT_VALUE_DATE_ONLY:
      return (
        <DateOnly
          {...(rest as SingleValueEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DEFAULT_VALUE_DATE_TIME:
      return (
        <DateTime
          {...(rest as SingleValueEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DATE_ONLY_RANGE:
      return (
        <DateOnlyRange
          {...(rest as DateRangeEditorProps)}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DATE_TIME_RANGE:
      return (
        <DateTimeRange
          {...(rest as DateRangeEditorProps)}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DEFAULT_VALUE_DATE_ONLY_RANGE:
      return (
        <DateOnlyRange
          {...(rest as DateRangeEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.DEFAULT_VALUE_DATE_TIME_RANGE:
      return (
        <DateTimeRange
          {...(rest as DateRangeEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
          config={{
            locale,
            formatter: formatDateOnly,
          }}
        />
      );
    case FieldType.USER:
      return <User {...(rest as ListEditorProps)} />;
    case FieldType.DEFAULT_VALUE_USER:
      return (
        <User
          {...(rest as ListEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.FILE:
      return <FileSelector {...(rest as ListEditorProps)} />;
    case FieldType.DEFAULT_VALUE_FILE:
      return (
        <FileSelector
          {...(rest as ListEditorProps)}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.DEFAULT_VALUE_LIST:
    case FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST:
      return (
        <CreatableMultipleList {...(rest as CreatableMultiListEditorProps)} />
      );
    case FieldType.DEFAULT_VALUE_CHECKBOX:
      return (
        <RadioGroup
          {...(rest as RadioGroupEditorProps)}
          groupFieldLayout={GroupFieldLayout.HORIZONTAL}
          popoverHelpContent={<DefaultValueInfo />}
        />
      );
    case FieldType.COLOR:
      return <ColorPicker {...(rest as ColorPickerProps)} />;
    case FieldType.TRANSFORM_NUMBER:
    case FieldType.TRANSFORM_TEXT:
    case FieldType.TRANSFORM_LIST:
      return (
        <TransformationsEditor {...(props as TransformationsEditorProps)} />
      );
    case FieldType.POPULATE_METHOD:
      return (
        <RadioGroup
          {...(rest as RadioGroupEditorProps)}
          label={undefined}
          groupFieldLayout={GroupFieldLayout.VERTICAL}
        />
      );
    default:
      return null;
  }
};

const topRightContentStyles: CSSProperties = {
  color: 'inherit',
  padding: 0,
  position: 'relative',
  float: 'right',
  right: 0,
  top: 0,
};

const EditorEnabledWithManagedIndicator = (props: MetaEditorProps) => {
  const { isExternallyManaged, externallyManagedWorkspaceId, ...rest } = props;
  if (!isExternallyManaged) {
    return <Editor {...rest} />;
  }
  return (
    <span>
      <span style={topRightContentStyles}>
        <ExternallyManagedIndicatorWithPopover
          workspaceId={externallyManagedWorkspaceId!}
        />
      </span>
      <Editor {...rest} />
    </span>
  );
};

const RemovableEditor = (props: RemovableMetaEditorProps) => {
  const { isRemovable, onRemove, ...rest } = props;
  if (!isRemovable) {
    return <EditorEnabledWithManagedIndicator {...rest} />;
  }
  return (
    <EditorEnabledWithManagedIndicator
      onCloseFieldClick={onRemove}
      closeFieldIconTooltip={REMOVE_FIELD_MESSAGE}
      {...rest}
    />
  );
};

export { RemovableEditor as Editor };

import { EditIcon } from '@ardoq/icons';
import { Field } from '@ardoq/forms';
import { ButtonGroup, SecondaryButton } from '@ardoq/button';

interface CalculatedFieldEditorSectionProps {
  lastUpdated: string;
  shouldShowEditButton: boolean;
  onEditButtonClick: VoidFunction;
  onRefreshButtonClick: VoidFunction;
}

const CalculatedFieldEditorSection = ({
  shouldShowEditButton,
  onEditButtonClick,
  onRefreshButtonClick,
}: CalculatedFieldEditorSectionProps) => (
  <Field>
    <ButtonGroup>
      <SecondaryButton onClick={onRefreshButtonClick}>
        Recalculate
      </SecondaryButton>

      {shouldShowEditButton && (
        <SecondaryButton onClick={onEditButtonClick}>
          <EditIcon />
        </SecondaryButton>
      )}
    </ButtonGroup>
    {/* Hidden until proper field can be displayed */}
    {/* <InputHintMessage>Last calculation: {lastUpdated}</InputHintMessage> */}
  </Field>
);

export default CalculatedFieldEditorSection;
